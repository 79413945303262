import { useState, useEffect } from "react";

const useTotalPrice = (formData) => {
  const [totalPrice, setTotalPrice] = useState({ eur: 24.99, usd: 24.99 });

  useEffect(() => {
    let priceEur = 24.99; // Base price in EUR
    let priceUsd = 24.99; // Base price in USD
    if (formData.personalizedVinyl) {
      priceEur += 125;
      priceUsd += 125;
    }
    setTotalPrice({ eur: priceEur, usd: priceUsd });
  }, [formData]);

  return totalPrice;
};

export default useTotalPrice;
